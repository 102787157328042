import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { X, Album, Plus, MapPin } from 'lucide-react'
import ButtonComp from '../../components/ButtonComp'
import CigarItem from '../../components/Dashboard/CigarItem'
import RatingComp from '../../components/RatingComp'
import { useUserStore } from '../../store/userStore';
import useFollower from '../../hooks/useFollower'
import JournalEntryDetails from '../Dashboard/Journal/JournalEntryDetails';
import CigarList from '../../components/Cigar/CigarList';
import { parseAddress } from '../../utils/parseAddress'
import { getBadgeImage } from '../../utils/badges'
import CigarIcon from "../../assets/cigaricon.svg";
import ProfileUserIcon from '../../assets/profile-user.svg';

interface RightSideProps {
  selected: number
  user: any
  setSelected: (value: number) => void
  topCigars: any[]
  isMobile: boolean
  refreshData?: () => void
  title?: string,
  isFollowerPage?: boolean,
}

const RightSide: React.FC<RightSideProps> = ({
  selected,
  user,
  setSelected,
  topCigars,
  isMobile,
  refreshData,
  title,
  isFollowerPage = false
}) => {
  const currentUser = useUserStore(state => state.user);
  const navigate = useNavigate()
  const [cigarSelected, setCigarSelected] = React.useState(0)
  const [cigar, setCigar] = React.useState<any>(null)
  const { handleAddFollowing, handleRemoveFollowing, loading } = useFollower(refreshData)
  console.log("user in right side", user);
  const onSelect = () => {
    setSelected(0)
    setCigarSelected(0)
  }

  const onCigarSelect = (id: number) => {
    let _cigar = user?.user_cigars.find((cigar: any) => cigar.id === id)
    setCigarSelected(id)
    setCigar(_cigar)
    setCigar(_cigar)
  }

  const onClickBadge = () => {
    // check if user.id is equal to current user id
    if (user.id === currentUser.id) {
      navigate('/dashboard/account')
    }
  }

  // If nothing is selected, return null
  if (selected === 0) return null
  const content = (
    <div
      className={`border border-primary-blue-90 rounded-2xl ${isMobile ? 'text-white' : 'col-span-2 row-span-2'
        }`}
    >
      <div className='flex flex-col items-center justify-center p-8 bg-primary-blue-90 rounded-2xl relative text-center'>
        <div className='w-full flex justify-end'>
          <X className='w-6 h-6 cursor-pointer !text-white' onClick={() => onSelect()} />
        </div>
        {
          cigarSelected == 0 ? (
            <>
              <img
                src={(user.profile_picture || ProfileUserIcon) ?? "/images/Profile.svg"}
                className='w-16 h-16 rounded-full'
                alt={user.name}
              />
              <div className='mt-4'>{user.name}</div>
              {(user.location != null && parseAddress(user.location) != '') ? (
                <div className='bg-primary-blue-80 flex items-center mt-2 rounded-2xl px-2 !text-white'>
                  <MapPin className='w-5 h-5 mr-2' />
                  <p className='mb-2 text-sm p-2 mt-1'>
                    {parseAddress(user.location)}
                  </p>
                </div>
              ) : null}
              <div className='mt-2 mb-2 !text-white'>
                {user.bio}
              </div>
              {
                !isFollowerPage ? (
                  user.id !== currentUser.id && !user.is_follower ? (
                    <ButtonComp
                      backgroundColor='bg-transparent'
                      textColor='text-secondary-gray'
                      loading={loading}
                      onClick={() => { handleAddFollowing(user.id) }}
                    >
                      <Plus /> Follow
                    </ButtonComp>
                  ) : (
                    user.id !== currentUser.id && user.is_follower && (
                      <ButtonComp
                        backgroundColor='bg-transparent'
                        textColor='text-secondary-gray'
                        loading={loading}
                        onClick={() => { handleRemoveFollowing(user.id) }}
                      >
                        Unfollow
                      </ButtonComp>
                    )
                  )
                ) : null
              }
            </>
          ) : (
            <>
              {cigar?.product_picture !== '' ? (
                <img src={cigar?.product_picture} alt={cigar?.product_picture} className='w-16 h-16' />
              ) : (
                <img src={CigarIcon} alt={cigar?.product_picture} className="w-12 h-12 fill-current text-white" />
              )
              }
              <div className='!text-white mt-4'>{cigar.product_name}</div>
              {cigar.location && (
                <div className='bg-primary-blue-60 flex items-center mt-2 rounded-2xl px-2'>
                  <MapPin className='w-5 h-5 mr-2' />
                  <p className='!text-white mb-2 text-sm p-2 mt-1'>
                    {parseAddress(cigar.location)}
                  </p>
                </div>
              )}

              <div className='!text-white mt-2 mb-2'>
                {cigar.experience_note}
              </div>
            </>
          )
        }
      </div>

      {cigarSelected === 0 && (
        <>
          <div className='flex flex-col mt-4 p-4'>
            <div className='flex items-center justify-between'>
              <h2 className='text-md font-semibold text-left text-gray-light'>
                All Badges
              </h2>
              <div className='flex items-center space-x-2'>
                <span className='text-md font-semibold text-gray-light'>{user?.badges?.length + 1}</span>
                <Album className='w-4 h-4 text-gray-light' />
              </div>
            </div>
            <div className='flex flex-wrap mt-4 space-x-2 p-2'>
              <div key={-1} className='w-12 h-12 rounded-full flex items-center justify-center mb-2 cursor-pointer' onClick={onClickBadge}>
                <img
                  key={-1}
                  src={`/badges/stoic icon light on dark round.svg`}
                  className='w-10 h-10'
                  alt='badge'
                />
              </div>
              {user?.badges?.map((badge: any, index: number) => (
                <div key={index} className={`w-12 h-12 rounded-full flex items-center justify-center mb-2 cursor-pointer`} onClick={onClickBadge}>
                  <img
                    src={`/badges/${getBadgeImage(badge)}.svg`}
                    className='w-10 h-10'
                    alt='badge'
                  />
                </div>
              ))}
            </div>
          </div>

          <CigarList
            title={title}
            user={user}
            topCigars={topCigars}
            onCigarSelect={onCigarSelect}
            onClose={() => setCigarSelected(0)}
            isMobile={isMobile}
          />


        </>
      )}

      {cigarSelected !== 0 && (
        <JournalEntryDetails selectedRating={cigar} />
      )}
    </div>
  )

  return isMobile ? (
    <div className='block md:hidden fixed inset-0 z-50 bg-primary-blue-90 overflow-y-auto'>
      {content}
    </div>
  ) : (
    <div className='hidden md:contents'>{content}</div>
  )
}

export default RightSide
